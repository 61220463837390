<template>
  <div>
    <div>NPay Test</div>
    <button @click="nPayReg">정기결제 등록</button>
    <div> {{ msg }}</div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";

  export default {
    name: "NPay",
    mixins: [
      UserAPIMixin
    ],
    created() {
      if(this.$route.query.resultCode) {
        this.reg();
      }
    },
    data() {
      return {
        url: 'https://dev.apis.naver.com/naverpay-partner',
        clientId: 'u86j4ripEt8LRfPGzQ8',
        msg: ''
      }
    },
    methods: {
      reg() {
        this.request.user.post('launchpack/v1/reg_bill_npay', {
          reserveId: this.$route.query.reserveId,
          tempReceiptId: this.$route.query.tempReceiptId
        }).then(res=>{
          this.msg = res.data.message;
        });
      },
      nPayReg() {
        // eslint-disable-next-line no-undef
        let oPay = Naver.Pay.create({
          payType: 'recurrent',
          mode: 'production',
          'clientId': this.clientId
        })
        oPay.open({
          "actionType" : "NEW",
          "targetRecurrentId": "",
          "productCode": "1",
          "productName": "플랫폼파이 서비스",
          "totalPayAmount": 100,
        "returnUrl": "http://localhost:8080/npay"
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
